import React, { Component } from 'react';
import { Header } from '../../components/index';
import { Heading2, Text, Heading3 } from '../../helper/Typography';
import { Container } from '../imprint/imprint';
import { SeoDataComponent } from '@lws/react-components';
import { mapMetaObject } from '../../helper/helperServices';

interface EntryPageProps {
  pageContext: {
    generalTexts: any;
    routeTexts: any;
    locale: string;
    id: string;
  };
}

class DataProtection extends Component<EntryPageProps> {
  render() {
    const {
      generalTexts,
      routeTexts: dataProtection,
      locale,
    } = this.props.pageContext;
    return (
      <main className={'App Light'}>
        <SeoDataComponent data={mapMetaObject(dataProtection.meta).data} />
        <Header
          darkTheme={false}
          lang={locale}
          pathname={this.props.location.pathname}
          generalTexts={generalTexts}
        />
        <Container>{locale === 'de' ? <De /> : <En />}</Container>
      </main>
    );
  }
}
const En = () => (
  <>
    <Heading2>Data Protection</Heading2>
    <Heading3>Principles</Heading3>
    <Text.p>
      Your trust is important to us. Neugelb Studios GmbH ("Neugelb" takes the
      protection of your personal data very seriously and complies with the data
      protection regulations, in particular to the provisions of the EU General
      Data Protection Regulation (GDPR). Personal data is processed only when
      the person concerned hasgiven their consent, when the data is necessary
      for the conclusion of a contract or when the GDPR or another law permits
      or prescribes the processing of the data
    </Text.p>
    <Heading3>Data processing on the websites</Heading3>
    <Text.p>
      Personal data is generally collected to the extent that is technically
      necessary. Under no circumstances is the data forwarded to third parties
      without the consent of those concerned. The following statements give you
      an overview of how the Neugelb guarantees this protection and also an
      overview of the type of data that is collected, processed or used on the
      websites and to which purpose.
    </Text.p>
    <Heading3>Recording</Heading3>
    <Text.p>
      Neugelb automatically collects and stores some of the information usually
      transferred by the browser in their server log files, so far as your
      browser makes this data available. This data cannot be connected with
      specific persons by Neugelb. This data is not combined with other data
      sources
    </Text.p>
    <Heading3>Cookies</Heading3>
    <Text.p>
      Our websites occasionally use cookies when you visit them. Cookies are
      text files which are filed on your computer and saved by your browser. The
      purpose of these is to make our service and offer user-friendly, effective
      and safe. Passwords are not deposited in cookies. Most of the cookies we
      use are so-called session cookies. They are automatically reset as soon as
      you log out. After you have ended the session by closing your browser, the
      cookie is saved for a certain amount of time and is subsequently
      overwritten or deleted by the client (e.g. your PC, your Tablet). Our
      cookies do not cause damage to your computer and contain no viruses.
    </Text.p>
    <Text.p>
      We also occasionally use cookies to measure success and optimize our
      advertising campaigns.
    </Text.p>
    <Heading3>Webtracking</Heading3>
    <Text.p>
      On our Internet pages, data is collected and stored in an anonymous form
      for optimization purposes only by means of web controlling technologies.
      From this data, user profiles are created under a pseudonym based on Art.
      6 (1 f) GDPR. Cookies can also be used for this purpose, but the data is
      only collected and stored in pseudonyms. The data are not used to
      personally identify the visitor of this website and they are not merged
      with data about the bearer of the pseudonym.You have the option to
      configure your browser so that you are informed as soon as cookies are
      placed and then reject them. In addition, you can prevent theinstallation
      of cookies by setting your web browser accordingly.It is possible that not
      all functions of this website can be used to their full extent. No
      personal data will be stored. An IP address of the user is made
      unrecognizable and does not allow any conclusions to be drawn about the
      visitors of this website.
    </Text.p>
    <Text.p>
      In addition, referrer-information (UTM-Parameter) in combination with
      personal information is processed, with an application for a jobs at
      Neugelb in order to track recruiting-campaigns. No additinal user data is
      processed. I can withdraw my consent at any time via e-mail to{' '}
      <a href="mailto:hello@neugelb.com">hello@neugelb.com</a>.
    </Text.p>
    <Heading3>Right of data access</Heading3>
    <Text.p>
      You have the right to receive information on the data stored about your
      person, the recipients thereof and the purpose of the data processing.
      Furthermore, we are available to answer your questions about the
      collection, processing and use of your personal data.
    </Text.p>
    <Heading3>Google Analytics</Heading3>
    <Text.p>
      This website uses Google Analytics including the Google Analytics
      advertising features. This is a web analysis service of Google Inc.
      ("Google"). Google Analytics uses so-called "cookies", text files that are
      stored on your computer and that allow an analysis of the use of the
      website by you.
    </Text.p>
    <Text.p>
      Google Analytics is used exclusively with activated IP anonymization
      (so-called IP masking). This means that Google's IP address will be
      truncated within member states of the European Union or other parties to
      the Agreement on the European Economic Area. Only in exceptional cases,
      when e.g. technical breakdowns in Europe, the full IP address is
      transmitted toa Google server in the US and shortened there.
    </Text.p>
    <Text.p>
      The IP anonymization method used by Google does not write the full IP
      address to disk at all times because all anonymization occurs almost
      immediately after receiving the request in memory.
    </Text.p>
    <Text.p>
      The IP address submitted by the user's browser will not be merged with
      other data provided by Google.
    </Text.p>
    <Text.p>
      On behalf of the operator of this website, Google will use this
      information to evaluate your use of the website on the basis of Art. 6 (1
      f) GDPR, to compile reports on website activity and other services related
      to website activity and internet usage, in particular also provide display
      advertising and Google Analytics demographic and interest reporting
      services to the site operator.
    </Text.p>
    <Text.p>
      Google may also transfer this information to third parties if required by
      law or as far as third parties process this data on behalf of Google. This
      will in no case be personal data.
    </Text.p>
    <Text.p>
      The Google Analytics demographics and interest reports use third-party
      data and third-party visitor data (such as age groups or interest groups)
      obtained through Google's interest-based advertising.You may opt out of
      the use of cookies by selecting the appropriate settings on your browser,
      however please note that if you do this you may not be able to use the
      full functionality of this website.You can also prevent data generated by
      the cookie as well as data relating to your website usage (including your
      IP address) from being saved or processed by Google by downloading and
      installing the browser plug-in available at the following link: <br />
      <a
        style={{ textDecoration: 'underline' }}
        href="https://tools.google.com/dlpage/gaoptout?hl=en"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download and install google browser-plugin
      </a>{' '}
      <br />
      For more information on the terms of use and data protection go to{' '}
      <a
        style={{ textDecoration: 'underline' }}
        href="https://www.google.de/analytics/terms/de.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://www.google.de/analytics/terms/de.html
      </a>{' '}
      or{' '}
      <a
        style={{ textDecoration: 'underline' }}
        href="https://www.google.de/intl/de/policies/"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://www.google.de/intl/de/policies/
      </a>
      . Please be advised that on this website, Google Analytics is supplemented
      with the code "anonymizelp" in order to guarantee that IP addresses are
      tracked in anonymised form (so-called IP masking).
    </Text.p>
    <Heading3>Snow Plow</Heading3>
    <Text.p>
      On neugelb.com we use the web tracking solution of SnowPlow Analytics
      Limited, d 32-38 Scrutton Street, London, Great Britain (“SnowPlow”). We
      use the data collected with the help of Snowplow exclusively for
      statistical purposes. In this case, we also set a cookie in your browser
      after you have released it so that we can recognize a returning user as
      such. The SnowPlow cookie contains a date and a non-recalculable random
      combination number (“hash”) as well as the number of the current session.{' '}
      <br />
      <br />
      You can find more information on terms of use and data protection as well
      as the parameters used{' '}
      <a
        href="https://github.com/snowplow/snowplow/wiki/snowplow-tracker-protocol"
        target="_blank"
      >
        here.
      </a>{' '}
      <br /> <br />
      By using these procedures, we do not receive any personal data about you,
      but rather only statistical information about the use on neugelb.com. In
      this way, we learn, for example, which content from neugelb.com is
      particularly popular, at what times our website is used particularly
      intensively, via which pages our users access our page, from where the
      user dialed into the Internet before he visits the site and which browsers
      and operating systems our users generally use when surfing on neugelb.com.
      We use this information to continuously improve the content and our site
      technically, creatively and editorially and to make it more convenient for
      you. Based on the statistical knowledge of browser type and operating
      system, we can optimize our web design. <br /> <br /> Snowplow is an open
      source web tracking solution managed by Snowplow Analytics Ltd., EC2A 4RQ
      London, UK. The version of Snowplow we use uses a tracking pixel and a
      cookie that can be used to track when, how many and how often users
      visited the neugelb.com website, which of our pages they called up in
      which order, which browser or which operating system you are using and
      which external links with redirects you have clicked on. <br /> <br /> The
      tracking pixel we use to collect this data is loaded by our tracking
      server if you have approved it in the cookie consent after acceptance. The
      recorded data is stored in a so-called tracking session together with an
      anonymous user ID (unique hash from browser properties and the current
      time). The data collection and processing takes place at any time without
      personal reference. In particular, we do not save complete IP addresses.{' '}
      <br />
      <br />
      If you do not consent to Snowplow's storage and use of the usage data
      described above, you can object to the data collection and use taking
      place during this web analysis measure by not selecting the checkbox in
      the cookie consent. For more information, see{' '}
      <a href="https://snowplowanalytics.com/privacy-policy/" target="_blank">
        Snowplow's privacy policy
      </a>{' '}
      . You can delete the cookie at any time via your browser. Instructions can
      be found in the help function of your browser.
    </Text.p>
    <Heading3>
      Processing personal data on the basis of the EU General Data Protection
      Regulation
    </Heading3>
    <Text.p>
      Neugelb processes personal data on the basis of the EU General Data
      Protection Regulation (GDPR). The following information provides an
      overview of the processing of these personal data by us and the rights
      resulting from data protection law.
    </Text.p>

    <Text.p style={{ textDecoration: 'underline' }}>
      <a href="https://neugelb.com/pdf/NGLB_Datenschutz_Information_Bewerber.pdf">
        Data Protection
      </a>
    </Text.p>
    <Heading3>Further information</Heading3>
    <Text.p>
      Should you require information that this declaration of data protection
      cannot provide or should you desire further information regarding a
      certain point, please contact the data protection officer of Neugelb
      Studios GmbH
    </Text.p>

    <Text.pGrey>© Neugelb Studios GmbH</Text.pGrey>
  </>
);

const De = () => (
  <>
    <Heading2>Datenschutzerklärung</Heading2>
    <Heading3>Datenschutz</Heading3>
    <Text.p>
      Ihr Vertrauen ist uns wichtig. Die Neugelb Studios GmbH („Neugelb“) nimmt
      den Schutz Ihrer persönlichen Daten sehr ernst und hält sich an die Regeln
      des Datenschutzes, insbesondere an die Vorschriften der
      EU-Datenschutzgrundverordnung (DSGVO). Personenbezogene Daten werden nur
      dann verarbeitet, wenn der Betroffene eingewilligt hat, diese für die
      Erfüllung eines Vertrages erforderlich sind oder die DSGVO oder ein
      anderes Gesetz die Verarbeitung erlaubt oder vorschreibt.
    </Text.p>
    <Heading3>Datenverarbeitung auf den Internetseiten </Heading3>
    <Text.p>
      Personenbezogene Daten werden grundsätzlich im technisch notwendigen
      Umfang erhoben. In keinem Fall werden die erhobenen Daten ohne Zustimmung
      der Betroffenen an Dritte weitergegeben. Die nachfolgenden Erklärungen
      geben Ihnen einen Überblick darüber, wie Neugelb diesen Schutz
      gewährleistet und welche Art von Daten zu welchem Zweck auf den
      Internetseiten erhoben, verarbeitet oder genutzt werden.
    </Text.p>
    <Heading3>Protokollierung</Heading3>
    <Text.p>
      Neugelb erhebt und speichert automatisch in ihren Server-Protokolldateien
      einige der üblicherweise vom Browser übermittelten Informationen, soweit
      Ihr Browser diese bereitstellt. Diese Daten können durch Neugelb nicht
      bestimmten Personen zugeordnet werden. Eine Zusammenführung dieser Daten
      mit anderen Datenquellen wird nicht vorgenommen.
    </Text.p>
    <Heading3>Cookies</Heading3>
    <Text.p>
      Beim Besuch auf unseren Internetseiten setzen wir gelegentlich Cookies
      ein, also kleine Textdateien, die auf Ihrem Rechner abgelegt werden und
      die Ihr Browser speichert. Sie dienen dazu, unser Angebot
      nutzerfreundlich, effektiv und sicher zu machen. Passwörter werden nicht
      in Cookies hinterlegt. Die meisten der von uns verwendeten Cookies sind so
      genannte Session-Cookies. Sie werden nach Ende Ihres Besuchs automatisch
      zurückgesetzt. Beenden Sie die Session, indem Sie Ihren Browser schließen,
      bleibt das Cookie für eine bestimmte Zeit gespeichert und wird
      anschließend überschrieben oder vom Client (z.B. Ihr PC, Ihr Tablet)
      gelöscht. Unsere Cookies richten auf Ihrem Rechner keinen Schaden an und
      enthalten keine Viren.
    </Text.p>
    <Heading3>Webtracking</Heading3>
    <Text.p>
      Auf unseren Internetseiten werden durch Web-Controlling-Technologien Daten
      in anonymisierter Form ausschließlich zu Optimierungszwecken gesammelt und
      gespeichert. Aus diesen Daten werden unter einem Pseudonym Nutzungsprofile
      auf der Grundlage von Art. 6 Abs. 1 f DSGVO erstellt. Hierzu können
      ebenfalls Cookies eingesetzt werden, die Daten jedoch ausschließlich in
      pseudonymer Form sammeln und speichern. Die Daten werden nicht dazu
      benutzt, den Besucher dieser Webseite persönlich zu identifizieren, und
      sie werden nicht mit Daten über den Träger des Pseudonyms zusammengeführt.
    </Text.p>
    <Text.p>
      Sie haben die Möglichkeit Ihren Browser so zu konfigurieren, dass Sie
      informiert werden, sobald Cookies platziert werden sollen, um diese dann
      eventuell abzulehnen. Darüber hinaus können Sie die Installation der
      Cookies durch eine entsprechende Einstellung Ihres Web-Browsers
      verhindern.
    </Text.p>
    <Text.p>
      Möglicherweise können dann nicht alle Funktionen dieser Internetseite in
      vollem Umfang genutzt werden. Es werden keinerlei personenbezogene Daten
      gespeichert. Eine IP-Adresse des Benutzers wird unkenntlich gemacht und
      erlaubt keinerlei Rückschlüsse auf die Besucher dieser Webseite.
    </Text.p>
    <Text.p>
      Zusätzlich werden Referrer-Informationen (UTM-Parameter)in Bezug auf eine
      Bewerbung von Neugelb mit einer Bewerbung personenbezogen ausgewertet, um
      die Quelle von Bewerbungen Kampagnen zuzuordnen. Darüber hinaus werden
      keine Nutzungsdaten personenbezogen von NEUGELB ausgewertet. Ich kann die
      Einwilligung mit Wirkung für die Zukunft jederzeit widerrufen, z.B. per
      Mail an <a href="mailto:hello@neugelb.com">hello@neugelb.com</a>.
    </Text.p>
    <Heading3>Auskunftsrecht</Heading3>
    <Text.p>
      Sie haben das Recht auf Auskunft über die zu Ihrer Person gespeicherten
      Daten, deren Empfänger sowie den Zweck der Datenverarbeitung. Weiterhin
      stehen wir Ihnen für Fragen zur Erhebung, Verarbeitung und Nutzung Ihrer
      personenbezogenen Daten zur Verfügung.
    </Text.p>
    <Heading3>Google Analytics</Heading3>
    <Text.p>
      Diese Website benutzt Google Analytics incl. der Google
      Analytics-Werbefunktionen. Dabei handelt es sich um einen Webanalysedienst
      der Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“,
      Textdateien, die auf Ihrem Computer gespeichert werden und die eine
      Analyse der Benutzung der Website durch Sie ermöglichen.
    </Text.p>
    <Text.p>
      Google Analytics wird ausschließlich mit aktivierter IP-Anonymisierung
      einsetzt (sog. IP-Masking). Dies bedeutet, dass die IP-Adresse der Nutzer
      von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in
      anderen Vertragsstaaten des Abkommens über den Europäischen
      Wirtschaftsraum gekürzt wird. Nur in Ausnahmefällen, wenn es z.B. zu
      technischen Ausfällen in Europa kommt, wird die volle IP-Adresse an einen
      Server von Google in den USA übertragen und dort gekürzt.
    </Text.p>
    <Text.p>
      Bei der von Google verwendeten IP-Anonymisierungsmethode wird die
      vollständige IPAdresse zu keinem Zeitpunkt auf eine Festplatte
      geschrieben, da die gesamte Anonymisierung fast unmittelbar nach Erhalt
      der Anforderung im Arbeitsspeicher erfolgt.
    </Text.p>
    <Text.p>
      Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit
      anderen Daten von Google zusammengeführt.
    </Text.p>
    <Text.p>
      Im Auftrag des Betreibers dieser Website wird Google diese Informationen
      benutzen, um Ihre Nutzung der Website auf der Grundlage von Art. 6 Abs. 1
      f DSGVO auszuwerten, um Reports über die Websiteaktivitäten
      zusammenzustellen und um weitere mit der Websitenutzung und der
      Internetnutzung verbundene Dienstleistungen, insbesondere auch Funktionen
      für Display-Werbung sowie Google Analytics-Berichte zur Leistung nach
      demografischen Merkmalen und Interessen gegenüber dem Websitebetreiber zu
      erbringen.
    </Text.p>
    <Text.p>
      Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen,
      sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im
      Auftrag von Google verarbeiten. Hierbei wird es sich in keinem Fall um
      personenbezogene Daten handeln
    </Text.p>
    <Text.p>
      In den Google Analytics-Berichten zur Leistung nach demografischen
      Merkmalen und Interessen werden über interessenbezogene Werbung von Google
      erlangte Daten und Besucherdaten von Drittanbietern (wie z.B.
      Altersgruppen, oder Interessensgruppen) verwendet.
    </Text.p>
    <Text.p>
      Sie können die Speicherung der Cookies durch eine entsprechende
      Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
      darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
      Funktionen dieser Website vollumfänglich werden nutzen können.
    </Text.p>
    <Text.p>
      Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und
      auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an
      Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem
      sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen
      und installieren: <br />
      <a
        style={{ textDecoration: 'underline' }}
        href="https://tools.google.com/dlpage/gaoptout?hl=en"
        target="_blank"
        rel="noopener noreferrer"
      >
        Google Browser-Plugin herunterladen und installieren{' '}
      </a>{' '}
      <br />
      Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie
      unter{' '}
      <a
        style={{ textDecoration: 'underline' }}
        href="https://www.google.de/analytics/terms/de.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://www.google.de/analytics/terms/de.html
      </a>{' '}
      bzw. unter{' '}
      <a
        style={{ textDecoration: 'underline' }}
        href="https://www.google.de/intl/de/policies/"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://www.google.de/intl/de/policies/
      </a>
    </Text.p>
    <Heading3>Snow Plow</Heading3>
    <Text.p>
      Wir setzen auf neugelb.com die Webtracking-Lösung der SnowPlow Analytics
      Limited, d 32-38 Scrutton Street, London, Groß Britannien (“SnowPlow”),
      ein. Wir verwenden die mit Hilfe von Snowplow erhobenen Daten
      ausschließlich für statistische Zwecke. In diesem Fall setzen wir zudem
      nach Ihrer Freigabe einen Cookie in Ihrem Browser, um einen
      wiederkehrenden Nutzer als solchen erkennen zu können. Der SnowPlow-Cookie
      enthält ein Datum und eine nicht zurückrechenbare
      Zufallskombinationsnummer („Hash“) sowie die Nummer der aktuellen Session.{' '}
      <br /> <br />
      Nähere Informationen zu Nutzungsbedingungen und Datenschutz und den
      verwendeten Parametern finden Sie{' '}
      <a
        href="https://github.com/snowplow/snowplow/wiki/snowplow-tracker-protocol"
        target="_blank"
      >
        hier .
      </a>
      <br /> <br />
      Durch den Einsatz dieser Verfahren erhalten wir keine personenbezogenen
      Daten über Sie, sondern vielmehr allein statistische Informationen über
      die Nutzung auf neugelb.com. Wir erfahren auf diesem Weg zum Beispiel,
      welche Inhalte von neugelb.com besonders beliebt sind, zu welchen Zeiten
      unsere Website besonders intensiv genutzt wird, über welche Seiten unsere
      Nutzer auf unsere Seite gelangen, von wo der Nutzer sich in das Internet
      eingewählt hat, bevor er die Seite besucht, und welche Browser und
      Betriebssysteme unsere Nutzer im Allgemeinen nutzen, wenn sie auf
      neugelb.com surfen. Diese Informationen nutzen wir, um den Content und
      unsere Seite  laufend technisch, gestalterisch und redaktionell zu
      verbessern und für Sie komfortabler zu machen. Anhand der statistischen
      Erkenntnisse zu Browsertyp und Betriebssystem können wir so unser
      Webdesign optimieren. <br />
      <br />
      Snowplow ist eine quelloffene Webtracking-Lösung, die von der Snowplow
      Analytics Ltd., EC2A 4RQ London, UK, verwaltet wird. Die von uns
      eingesetzte Variante von Snowplow verwendet einen Tracking-Pixel und einen
      Cookie, mit dem nachverfolgt werden kann, wann und wie viele Nutzer die
      neugelb.com Website wie oft besucht haben, welche unserer Seiten sie in
      welcher Reihenfolge aufgerufen haben, welcher Browser bzw. welches
      Betriebssystem sie verwenden und auf welche externen Links mit
      Weiterleitungen sie geklickt haben. <br />
      <br />
      Der von uns zur Erfassung dieser Daten verwendete Tracking-Pixel wird von
      unserem Tracking-Server geladen, wenn Sie diesen nach Akzeptanz im Cookie
      Consent freigegeben haben. Die erfassten Daten werden in einer sogenannten
      Tracking-Session gemeinsam mit einer anonymen Nutzer-Kennung (eindeutiger
      Hash aus Browsereigenschaften und dem aktuellen Zeitpunkt) gespeichert.
      Die Datenerhebung und Verarbeitung erfolgt dabei zu jedem Zeitpunkt ohne
      Personenbezug. Insbesondere speichern wir keine vollständigen IP-Adressen.{' '}
      <br />
      <br />
      Wenn Sie mit der zuvor beschriebenen Speicherung und Verwendung der
      Nutzungsdaten durch Snowplow nicht einverstanden sind, können Sie Ihren
      Widerspruch gegen die bei dieser Webanalyse-Maßnahmen stattfindende
      Datenerhebung und -verwendung erklären indem Sie die Checkbox im Cookie
      Consent nicht auswählen. Weitere Informationen finden Sie in der{' '}
      <a href="https://snowplowanalytics.com/privacy-policy/" target="_blank">
        Datenschutzerklärung von Snowplow
      </a>
      . Den gesetzten Cookie können Sie über Ihren Browser jederzeit löschen.
      Eine Anleitung finden Sie in der Hilfefunktion Ihres Browsers.
    </Text.p>
    <Text.p>
      Wir weisen Sie darauf hin, dass auf dieser Website Google Analytics um den
      Code „anonymizeIp“ erweitert wurde, um eine anonymisierte Erfassung von
      IP-Adressen (sog. IP-Masking) zu gewährleisten.
    </Text.p>
    <Heading3>
      Verarbeitung personenbezogener auf der Grundlage der
      EU-Datenschutzgrundverordnung
    </Heading3>
    <Text.p>
      Neugelb verarbeitet personenbezogene Daten auf der Grundlage der EU
      Datenschutzgrundverordnung (DSGVO). Mit den folgenden Informationen geben
      wir einen Überblick über die Verarbeitung dieser personenbezogenen Daten
      durch uns und die sich aus dem Datenschutzrecht ergebenden Rechte:{' '}
      <a
        style={{ textDecoration: 'underline' }}
        href="https://neugelb.com/pdf/NGLB_Datenschutz_Information_Bewerber.pdf"
      >
        Datenschutzinformation
      </a>
    </Text.p>
    <Heading3>Weitere Informationen</Heading3>
    <Text.p>
      Wenn Sie Informationen wünschen, die Ihnen diese Datenschutzerklärung
      nicht geben kann oder wenn Sie zu einem bestimmten Punkt weitere Auskünfte
      wünschen, wenden Sie sich bitte an den Datenschutzbeauftragten der Neugelb
      Studios GmbH.
    </Text.p>

    <Text.pGrey>© Neugelb Studios GmbH</Text.pGrey>
  </>
);

export default DataProtection;
